<template>
  <div class="form">
    <div class="form-header">
      <!--      <div>Bireysel</div>-->
      <div class="active">Kurumsal</div>
    </div>
    <div class="form-body">
      <form @submit.prevent="submit" class="row top-xs">
        <div
          class="col-xs-12 col-md-6 form-group"
          :class="{ 'form-group--error': $v.company.companyName.$error }"
        >
          <label class="form__label">Şirket Adı *</label>
          <input
            type="text"
            class="form__input"
            ref="companyName"
            v-model.trim="$v.company.companyName.$model"
          />
        </div>
        <div class="col-xs-12 col-md-6"></div>

        <div
          class="col-xs-12 col-md-6 form-group"
          :class="{
            'form-group--error': $v.company.authorizedPerson.firstName.$error
          }"
        >
          <label class="form__label">Yetkili Kişi Adı *</label>
          <input
            type="text"
            class="form__input"
            ref="authorizedPerson"
            v-model.trim="$v.company.authorizedPerson.firstName.$model"
          />
        </div>

        <div
          class="col-xs-12 col-md-6 form-group"
          :class="{
            'form-group--error': $v.company.authorizedPerson.lastName.$error
          }"
        >
          <label class="form__label">Yetkili Kişi Soyadı *</label>
          <input
            type="text"
            class="form__input"
            ref="authorizedPerson"
            v-model.trim="$v.company.authorizedPerson.lastName.$model"
          />
        </div>

        <div
          class="col-xs-12 col-md-6 form-group"
          :class="{
            'form-group--error':
              $v.company.authorizedPerson.identityNumber.$error
          }"
        >
          <label class="form__label">Yetkili Kişi TC Kimlik No *</label>
          <input
            type="text"
            class="form__input"
            ref="authorizedPerson"
            v-model.trim="$v.company.authorizedPerson.identityNumber.$model"
          />
        </div>

        <div class="col-xs-12 col-md-6"></div>

        <div
          class="col-xs-12 col-md-6 form-group"
          :class="{ 'form-group--error': $v.company.phoneNumber.$error }"
        >
          <label class="form__label">Telefon *</label>
          <input
            type="text"
            class="form__input"
            ref="phoneNumber"
            placeholder="+9053XXXXXXXX"
            v-model.trim="$v.company.phoneNumber.$model"
          />
        </div>

        <div
          class="col-xs-12 col-md-6 form-group"
          :class="{ 'form-group--error': $v.company.email.$error }"
        >
          <label class="form__label">E-posta Adresi *</label>
          <input
            type="text"
            class="form__input"
            v-model.trim="$v.company.email.$model"
          />
        </div>

        <div
          class="col-xs-12 col-md-6 form-group"
          :class="{ 'form-group--error': $v.company.taxOffice.$error }"
        >
          <label class="form__label">Vergi Dairesi *</label>
          <input
            type="text"
            class="form__input"
            v-model.trim="$v.company.taxOffice.$model"
          />
        </div>

        <div
          class="col-xs-12 col-md-6 form-group"
          :class="{ 'form-group--error': $v.company.taxNumber.$error }"
        >
          <label class="form__label">Vergi Numarası *</label>
          <input
            type="text"
            class="form__input"
            v-model.trim="$v.company.taxNumber.$model"
          />
        </div>

        <div class="col-xs-12 col-md-6 form-group multiple-form">
          <input type="radio" id="EIC" value="EIC" v-model="company.codeType" />
          <label class="form__label" for="EIC">EIC Kodu</label>
          <input
            type="radio"
            id="TEKIL"
            value="TEKIL"
            v-model="company.codeType"
          />
          <label class="form__label" for="TEKIL">Tekil Kodu</label>
        </div>

        <div class="col-xs-12 col-md-6"></div>

        <div
          v-if="this.company.codeType === 'EIC'"
          class="col-xs-12 col-md-6 form-group"
          :class="{ 'form-group--error': $v.company.eicCode.$error }"
        >
          <div class="tooltip">
            <img src="@/assets/images/info.svg" alt="" />
            <span class="tooltiptext"
              >Bu alana EIC Kodu ya da Tekil Kod değerlerinden birini girmeniz
              yeterlidir. EIC Kodu, sayacınızın 40z ile baslayan 16 haneli
              benzersiz kodudur. Bu bilgiyi faturanız üzerinde
              bulabilirsiniz.</span
            >
          </div>
          <label class="form__label">EIC Kodu *</label>
          <input
            type="text"
            class="form__input"
            placeholder="EIC Kodu girin"
            ref="eicCode"
            v-model.trim="$v.company.eicCode.$model"
          />
        </div>
        <div
          v-if="this.company.codeType !== 'EIC'"
          class="col-xs-12 col-md-6 form-group"
          :class="{ 'form-group--error': $v.company.tekilCode.$error }"
        >
          <div class="tooltip">
            <img src="@/assets/images/info.svg" alt="" />
            <span class="tooltiptext"
              >Bu alana EIC Kodu ya da Tekil Kod değerlerinden birini girmeniz
              yeterlidir. Tekil Kodu, dağıtım şirketleri tarafından dağıtım
              bölgelerindeki her bir tüketim noktası için belirlenen ve tüketim
              noktasının Piyasa Yönetim Sistemine kaydı için de kullanılan
              münhasır kayıt kodunu ifade etmektedir. Bu bilgiyi faturanız
              üzerinde bulabilirsiniz.</span
            >
          </div>
          <label class="form__label">Tekil Kodu *</label>
          <input
            type="text"
            class="form__input"
            placeholder="Tekil Kodu girin"
            ref="tekilCode"
            v-model.trim="$v.company.tekilCode.$model"
          />
        </div>

        <div
          class="col-xs-12 col-md-6 form-group"
          :class="{ 'form-group--error': $v.company.meterController.id.$error }"
        >
          <label for="meterController" class="form__label"
            >Sayaç Okuyan Kurum *</label
          >
          <select
            id="meterController"
            v-model="$v.company.meterController.id.$model"
          >
            <option value="" disabled hidden>Seçiniz</option>
            <option
              v-for="option in controllerInstitutions"
              v-bind:value="option.id"
              :key="option.id"
            >
              {{ option.name }}
            </option>
          </select>
        </div>

        <div
          class="col-xs-12 col-md-6 form-group"
          :class="{ 'form-group--error': $v.company.meterCity.id.$error }"
        >
          <label for="province" class="form__label"
            >Sayacın Bulunduğu İl *</label
          >
          <select
            id="province"
            v-model="$v.company.meterCity.id.$model"
            @change="getDistricts"
          >
            <option value="" disabled hidden>Seçiniz</option>
            <option
              v-for="option in cities"
              v-bind:value="option.id"
              :key="option.id"
            >
              {{ option.name }}
            </option>
          </select>
        </div>

        <div
          class="col-xs-12 col-md-6 form-group"
          :class="{ 'form-group--error': $v.company.meterDistrict.id.$error }"
        >
          <label for="districts" class="form__label"
            >Sayacın Bulunduğu İlçe *</label
          >
          <select id="districts" v-model="$v.company.meterDistrict.id.$model">
            <option value="" disabled hidden>Seçiniz</option>
            <option
              v-for="option in districts"
              v-bind:value="option.id"
              :key="option.id"
            >
              {{ option.name }}
            </option>
          </select>
        </div>

        <div
          class="col-xs-12 col-md-6 form-group"
          :class="{ 'form-group--error': $v.company.meterFullAddress.$error }"
        >
          <label class="form__label">Sayaç Adresi *</label>
          <textarea
            style="height: 100px"
            class="form__input"
            v-model.trim="$v.company.meterFullAddress.$model"
          />
        </div>

        <div
          class="col-xs-12 col-md-6 form-group"
          :class="{ 'form-group--error': $v.company.lastBillAmount.$error }"
        >
          <div class="tooltip">
            <img src="@/assets/images/info.svg" alt="" />
            <span class="tooltiptext"
              >Son elektrik faturanızdaki tüm vergiler (BTV, KDV vb.) ve fonlar
              dahil toplam tutardır. Teminat bedelinin belirlenmesinde bu tutar
              kullanılacaktır.</span
            >
          </div>
          <label class="form__label">Son Fatura Tutarı (TL) *</label>
          <input
            type="text"
            class="form__input"
            v-mask="currencyMask"
            v-model.trim="$v.company.lastBillAmount.$model"
          />
        </div>

        <div
          class="col-xs-12 col-md-6 form-group"
          :class="{ 'form-group--error': $v.taxPlates.$error }"
        >
          <label class="form__label" for="taxPlates"
            >Vergi Levhası Yükle *</label
          >
          <div class="form__input file_input">
            <input
              type="file"
              multiple
              @change="inputFile($event, 'taxPlates')"
              ref="taxPlates"
              accept="application/pdf, image/jpeg, image/png"
            />
          </div>
        </div>

        <div
          class="col-xs-12 col-md-6 form-group"
          :class="{ 'form-group--error': $v.signatureCirculars.$error }"
        >
          <label class="form__label" for="signatureCirculars"
            >İmza Sirküleri Yükle (Opsiyonel)</label
          >
          <div class="form__input file_input">
            <input
              type="file"
              multiple
              @change="inputFile($event, 'signatureCirculars')"
              ref="signatureCirculars"
              accept="application/pdf, image/jpeg, image/png"
            />
          </div>
        </div>

        <div
          class="col-xs-12 col-md-6 form-group"
          :class="{ 'form-group--error': $v.bills.$error }"
        >
          <label class="form__label" for="bills">
            Son Elektrik Faturasını Yükle *
          </label>
          <div class="form__input file_input">
            <input
              type="file"
              multiple
              @change="inputFile($event, 'bills')"
              ref="bills"
              accept="application/pdf, image/jpeg, image/png"
            />
          </div>
        </div>

        <div class="col-xs-12 col-md-6 form-group">
          <div class="">
            <span class="tooltiptext">
              <img
                src="@/assets/images/info.svg"
                alt=""
                style="display: inline-block"
              />
              Bu alana birden fazla dosya yükleyebilirsiniz. Dosyalar PDF, PNG
              veya JPG formatında olmalı ve her bir dosya için 5MB boyutunu
              geçmemelidir.
            </span>
          </div>
        </div>

        <div
          class="col-xs-12 form-group"
          :class="{
            'form-group--error': $v.company.clarificationTextAccepted.$error
          }"
        >
          <input
            type="checkbox"
            id="clarification"
            v-model="company.clarificationTextAccepted"
          />
          <label class="form__label" for="clarification">
            <a href="#" @click="$refs.clarificationModal.openModal()">
              Aydınlatma Metni
            </a>
            ’ni okudum onaylıyorum.
          </label>
        </div>

        <modal ref="clarificationModal">
          <template v-slot:header>
            <div class="popup-header">Aydınlatma Metni</div>
          </template>
          <template v-slot:body>
            <div v-html="clarificationText"></div>
          </template>
        </modal>

        <div
          class="col-xs-12 form-group"
          :class="{
            'form-group--error': $v.company.consentAccepted.$error
          }"
        >
          <input
            type="checkbox"
            id="consent"
            v-model="company.consentAccepted"
          />
          <label class="form__label" for="consent">
            <a href="#" @click="$refs.consentModal.openModal()">
              Açık Rıza Metni
            </a>
            ’ni okudum onaylıyorum.
          </label>
        </div>

        <modal ref="consentModal">
          <template v-slot:header>
            <div class="popup-header">Açık Rıza Metni</div>
          </template>
          <template v-slot:body>
            <div v-html="consentText"></div>
          </template>
        </modal>

        <div
          class="col-xs-12 form-group"
          :class="{
            'form-group--error': $v.company.accurateDeclarationAccepted.$error
          }"
        >
          <input
            type="checkbox"
            id="confirmation"
            v-model="company.accurateDeclarationAccepted"
          />
          <label class="form__label" for="confirmation">
            Tarafımca yukarıda vermiş olduğum bilgilerin doğruluğunu ve
            bilgilerin hatalı olması durumunda doğacak yükümlülüğü kabul
            ediyorum.
          </label>
        </div>

        <div class="col-xs-12 col-lg-12 form-group center-xs">
          <h4 align="left" style="margin-bottom: 10px">
            Teklifimizi sözleşme adımında görüntüleyebilirsiniz. Bu adımda ödeme
            <u>alınmayacaktır</u>.
          </h4>
          <button
            class="button"
            type="submit"
            :disabled="loading"
            :class="{ loading: loading }"
          >
            İleri
          </button>
          <p class="notify-title" v-if="error">
            Hata: <br />
            {{
              error.response && error.response.data && error.response.data.code
                ? $localeError(error.response.data.code)
                : error
            }}
          </p>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Modal from '@/components/partials/Modal.vue'
import firebase from 'firebase/app'
import { each } from 'lodash'
import {
  required,
  requiredIf,
  minLength,
  maxLength,
  email
} from 'vuelidate/lib/validators'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
const currencyMask = createNumberMask({
  suffix: '',
  prefix: '',
  allowDecimal: true,
  includeThousandsSeparator: false,
  allowNegative: false
})

const validTCKimlik = tcno => {
  if (!tcno) return false
  tcno = String(tcno)
  if (tcno.substring(0, 1) === '0') {
    return false
  }
  if (tcno.length !== 11) {
    return false
  }
  let ilkon_array = tcno.substr(0, 10).split('')
  let ilkon_total = 0,
    hane_tek = 0,
    hane_cift = 0
  for (let i = 0, j = 0; i < 9; ++i) {
    j = parseInt(ilkon_array[i], 10)
    if (i & 1) {
      hane_cift += j
    } else {
      hane_tek += j
    }
    ilkon_total += j
  }
  if ((hane_tek * 7 - hane_cift) % 10 !== parseInt(tcno.substr(-2, 1), 10)) {
    return false
  }
  ilkon_total += parseInt(ilkon_array[9], 10)
  if (ilkon_total % 10 !== parseInt(tcno.substr(-1), 10)) {
    return false
  }
  return true
}

export default {
  name: 'Form-1',
  components: {
    Modal
  },
  data() {
    return {
      company: {
        companyName: '',
        authorizedPerson: {
          firstName: '',
          lastName: '',
          identityNumber: ''
        },
        phoneNumber: '+90',
        email: '',
        taxOffice: '',
        taxNumber: '',
        codeType: 'EIC',
        eicCode: '',
        tekilCode: '',
        meterController: {
          id: ''
        },
        meterCity: {
          id: ''
        },
        meterDistrict: {
          id: ''
        },
        meterFullAddress: '',
        lastBillAmount: '',
        clarificationTextAccepted: false,
        consentAccepted: false,
        accurateDeclarationAccepted: false,
        salesRepresentativeNumber: this.$store.getters.salesRepNumber
      },
      controllerInstitutions: '',
      cities: '',
      districts: '',
      taxPlates: [],
      signatureCirculars: [],
      bills: [],
      clarificationText: '',
      consentText: '',
      showModal: false,
      subscriber: '',
      error: '',
      loading: false,
      currencyMask: currencyMask
    }
  },
  validations: {
    company: {
      companyName: {
        required,
        minLength: minLength(2)
      },
      authorizedPerson: {
        firstName: {
          required,
          minLength: minLength(2)
        },
        lastName: {
          required,
          minLength: minLength(2)
        },
        identityNumber: {
          validTCKimlik
        }
      },
      phoneNumber: {
        required,
        phoneNumberValid: value => /^[+][0-9]{10,15}$/.test(value)
      },
      email: {
        required,
        email
      },
      taxOffice: {
        required,
        minLength: minLength(3)
      },
      taxNumber: {
        required,
        taxNumberValid: value => /^[0-9]{10,11}$/.test(value)
      },
      codeType: {
        required
      },
      eicCode: {
        required: requiredIf(company => company.codeType === 'EIC'),
        maxLength: maxLength(16),
        minLength: minLength(16)
      },
      tekilCode: {
        required: requiredIf(company => company.codeType !== 'EIC'),
        minLength: minLength(5)
      },
      meterController: {
        id: {
          required
        }
      },
      meterCity: {
        id: {
          required
        }
      },
      meterDistrict: {
        id: {
          required
        }
      },
      meterFullAddress: {
        required,
        minLength: minLength(5)
      },
      lastBillAmount: {
        required,
        minLength: minLength(1)
      },
      clarificationTextAccepted: {
        checked: value => value === true
      },
      consentAccepted: {
        checked: value => value === true
      },
      accurateDeclarationAccepted: {
        checked: value => value === true
      }
    },
    taxPlates: {
      required
    },
    signatureCirculars: {},
    bills: {
      required
    }
  },
  async created() {
    this.getControllerInstitutions()
    this.getCities()
    this.getClarification()
    this.getConsent()
    this.fillData()
  },
  mounted() {
    firebase.analytics().logEvent('campaign-form')
  },
  methods: {
    async submit() {
      this.error = 'Şu anda bakımdayız. Lütfen daha sonra tekrer deneyiniz.'

      // validate form
//      this.$v.$touch()

//      if (!this.$v.$invalid) {
//        await this.subscribe()
//      } else {
//        this.error = 'Lütfen bilgilerinizi kontrol edip tekrar deneyiniz!'
//      }
    },
    async subscribe() {
      try {
        this.loading = true
        // prepare formData
        const formData = new FormData()
        this.company.id = ''
        formData.append('subscriber', JSON.stringify(this.company))
        each(this.taxPlates, function(v) {
          formData.append('taxPlates', v)
        })
        each(this.signatureCirculars, function(v) {
          formData.append('signatureCirculars', v)
        })
        each(this.bills, function(v) {
          formData.append('bills', v)
        })

        const { data } = await this.$axios.post(
          `${process.env.VUE_APP_APIURL}/subscribers`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        )
        // console.log('subscribe data', data)
        this.subscriber = data
        this.$store.commit('setSubscriber', data)
        this.$store.commit('setCompleted', 1)
        this.$store.commit('setStep', 2)
        this.loading = false
      } catch (error) {
        // console.log('subscribe error', error.response.data || error)
        this.error = error
        this.loading = false
      }
    },
    async getClarification() {
      this.showModal = true
      try {
        const { data } = await this.$axios.get(
          `${process.env.VUE_APP_APIURL}/clarification-text`
        )
        this.clarificationText = data.content
      } catch (error) {
        this.error = error
      }
    },
    async getConsent() {
      this.showModal = true
      try {
        const { data } = await this.$axios.get(
          `${process.env.VUE_APP_APIURL}/consent`
        )
        this.consentText = data.content
      } catch (error) {
        this.error = error
      }
    },
    async getControllerInstitutions() {
      try {
        const { data } = await this.$axios.get(
          `${process.env.VUE_APP_APIURL}/controller-institutions`
        )
        this.controllerInstitutions = data
      } catch (error) {
        this.error = error
      }
    },
    async getCities() {
      try {
        const { data } = await this.$axios.get(
          `${process.env.VUE_APP_APIURL}/cities`
        )
        this.cities = data
      } catch (error) {
        this.error = error
      }
    },
    async getDistricts() {
      try {
        const { data } = await this.$axios.get(
          `${process.env.VUE_APP_APIURL}/cities/${this.company.meterCity.id}/districts`
        )
        this.districts = data
      } catch (error) {
        this.error = error
      }
    },
    fillData() {
      const subscriber = this.$store.getters.subscriber
      if (subscriber.id) {
        this.company = subscriber
        this.getDistricts()
      }
    },
    inputFile(e, key) {
      this[key] = [] // reset file model
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      // console.log("files", this.filesOne);
      for (let i = 0; i < files.length; i++) {
        // console.log("file", i, this.filesOne[i]);
        this[key].push(files[i])
      }
    }
  }
}
</script>
