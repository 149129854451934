<template>
<div class="container wizard" v-if="loading">
  <div class="form">
       <img src="@/assets/images/tenor.gif" class="fullscreenLoader"  />
  </div>
  </div>
  
  <div class="container wizard" v-else-if="this.paymentScreen">
    
    <div class="form">
      <section class="success" v-if="success">
        <div class="box-header">
          <img class="message-image" src="@/assets/images/checked.svg" alt="" />
          Kart Bilgileriniz Başarıyla Güncellendi
        </div>
      </section>
      <div class="form-body">
        <form @submit.prevent="complete" class="row top-xs">
          <div
            class="col-xs-12 form-group"
            :class="{
              'form-group--error': $v.paymentForm.cardNumber.$error
            }"
          >
            <input
              type="text"
              class="form__input"
              ref="cardNumber"
              placeholder="Kart Numarası"
              v-mask="'####-####-####-####'"
              v-model.trim="$v.paymentForm.cardNumber.$model"
            />
          </div>
          <div
            class="col-xs-12 form-group"
            :class="{
              'form-group--error': $v.paymentForm.cardHolderName.$error
            }"
          >
            <input
              type="text"
              class="form__input"
              ref="cardHolderName"
              placeholder="Kart Üzerindeki İsim"
              v-model.trim="$v.paymentForm.cardHolderName.$model"
            />
          </div>
          <div
            class="col-xs-12 col-md-6 form-group"
            :class="{
              'form-group--error': $v.paymentForm.expireDate.$error
            }"
          >
            <input
              type="text"
              class="form__input"
              ref="expireDate"
              placeholder="AA/YY"
              v-mask="'##/##'"
              v-model.trim="$v.paymentForm.expireDate.$model"
            />
          </div>
          <div
            class="col-xs-12 col-md-6 form-group"
            :class="{
              'form-group--error': $v.paymentForm.cvc.$error
            }"
          >
            <div class="tooltip">
              <img src="@/assets/images/info.svg" alt="" />
              <span class="tooltiptext"
                >CVC kodu, 3 haneli olarak kartların arka yüzünde yer
                alır.</span
              >
            </div>
            <input
              type="text"
              class="form__input"
              ref="cvc"
              placeholder="CVC"
              v-mask="'###'"
              v-model.trim="$v.paymentForm.cvc.$model"
            />
          </div>
          <div
            class="col-xs-12 form-group"
            :class="{
              'form-group--error': $v.paymentForm.saveMyCard.$error
            }"
          >
            <input
              type="checkbox"
              id="saveMyCard"
              v-model="$v.paymentForm.saveMyCard.$model"
            />
            <label class="form__label" for="saveMyCard">
              Kart bilgilerimin sonraki işlemlerde de kullanılmasını kabul
              ediyorum
            </label>
          </div>
          <!-- <div class="col-xs-12 col-md-12">
          <img src="@/assets/images/logo-band.png" alt="" />
        </div>-->
        </form>
        <div class="center-xs">
          <button class="button" @click="complete">
            Kart Bilgilerini Güncelle
          </button>
          <p v-if="error" class="notify-title">
            Hata: <br />
            {{
              error.response && error.response.data && error.response.data.code
                ? $localeError(error.response.data.code)
                : error
            }}
          </p>
        </div>
      </div>
    </div>
    <modal ref="threeDSecureModal">
      <template v-slot:header>
        <div class="popup-header"></div>
      </template>
      <template v-slot:body>
        <iframe
          :srcDoc="paymentInit.htmlContent"
          width="100%"
          height="300px"
          frameborder="0"
        ></iframe>
      </template>
    </modal>
  </div>
  <div class="container wizard" v-else-if="this.paymentLink">
    <div class="form">
      <section class="notify-title">
        <div class="box-header">
          Geçersiz ya da süresi dolmuş bir link girdiniz. Lütfen kontrol ediniz
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import firebase from 'firebase/app'
import Modal from '@/components/partials/Modal.vue'

const validCreditCard = value => {
  if (!value) return false
  if (/[^0-9-\s]+/.test(value)) return false

  // The Luhn Algorithm. It's so pretty.
  let nCheck = 0,
    bEven = false
  value = value.replace(/\D/g, '')

  for (var n = value.length - 1; n >= 0; n--) {
    var cDigit = value.charAt(n),
      nDigit = parseInt(cDigit, 10)

    if (bEven && (nDigit *= 2) > 9) nDigit -= 9

    nCheck += nDigit
    bEven = !bEven
  }

  return nCheck % 10 == 0
}

export default {
  name: 'CardUpdate',
  components: {
    Modal
  },
  data() {
    return {
      paymentForm: {
        cardNumber: '',
        cardHolderName: '',
        expireDate: '',
        cvc: '',
        saveMyCard: false
      },
      summary: {},
      paymentLink: false,
      error: false,
      success: false,
      paymentScreen: false,
      loading: false
    }
  },
  validations: {
    paymentForm: {
      cardNumber: {
        required,
        validCreditCard,
        minLength: minLength(13),
        maxLength: maxLength(19)
      },
      cardHolderName: {
        required,
        minLength: minLength(3)
      },
      expireDate: {
        required,
        minLength: minLength(5)
      },
      cvc: {
        required,
        minLength: minLength(3)
      },
      saveMyCard: {
        checked: value => value === true
      }
    }
  },
  mounted() {
    this.loading = true;
    try {
      axios
        .request({
          url: `${process.env.VUE_APP_APIURL}/subscribers/${this.$route.params.subscriberId}/card-update-requests?token=${this.$route.query.token}`,
          method: 'get'
        })
        .then(response => {
          console.log(response)
          if(response.status){
            this.paymentScreen = true
            this.loading = false;
          }
        })
        .catch(err => {
          console.log('error:' + err)
          this.paymentLink = true
          this.loading = false;
        })
    } catch (error) {
      console.log('error' + error)
      this.paymentLink = true
      this.loading = false;
    }

    firebase.analytics().logEvent('campaign-payment')
  },
  methods: {
    async complete() {
      this.error = ''
      this.$v.$touch()
      if (this.$v.$invalid) {
        if (!this.$v.paymentForm.saveMyCard.checked) {
          return (this.error =
            'Abonelik süreci için kart bilgilerimin sonraki işlemlerde de kullanılmasını kabul etmelisiniz.')
        }
        return (this.error =
          'Lütfen bilgilerinizi kontrol edip tekrar deneyiniz!')
      }
      await this.makePayment()
    },
    async makePayment() {
      try {
        await this.$axios
          .put(
            `${process.env.VUE_APP_APIURL}/subscribers/${this.$route.params.subscriberId}/card?token=${this.$route.query.token}`,
            {
              cardNumber: this.paymentForm.cardNumber.replace(/-/gi, ''),
              expireYear: `${this.paymentForm.expireDate.substring(3, 5)}`,
              expireMonth: this.paymentForm.expireDate.substring(0, 2),
              cvc: this.paymentForm.cvc,
              cardHolderName: this.paymentForm.cardHolderName,
              saveMyCard: this.paymentForm.saveMyCard
            }
          )
          .then(response => {
            if(response.status){
              this.success = true
              this.$v.$reset();
              this.paymentForm = {
                cardNumber: '',
                cardHolderName: '',
                expireDate: '',
                cvc: '',
                saveMyCard: false
              }
            }
          })
          .catch(err => {
            console.log('error:' + err)
          })
      } catch (error) {
        this.error = error
      }
    }
  }
}
</script>
<style scoped>
.fullscreenLoader {
      max-width: 100%;
      max-height: 100%;
      bottom: 0;
      left: 0;
      margin: auto;
    }
</style>