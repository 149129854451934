<template>
  <section class="agrement">
    <div class="box-header">Mesafeli Satış Sözleşmesi</div>
    <div class="agrement-content" v-html="contract"></div>
    <div
      class="form-group"
      :class="{ 'form-group--error': $v.acceptContract.$error }"
    >
      <input
        type="checkbox"
        id="confirmation"
        value="Confirmed"
        v-model.trim="$v.acceptContract.$model"
      />
      <label class="form__label" for="confirmation"
        >Mesafeli Satış Sözleşmesini okudum ve kabul ediyorum.</label
      >
    </div>
    <div
      class="form-group"
      :class="{ 'form-group--error': $v.acceptInformation.$error }"
    >
      <input
        type="checkbox"
        id="information"
        value="Confirmed"
        v-model.trim="$v.acceptInformation.$model"
      />
      <label class="form__label" for="information"
        >12 ay boyunca faturalarımın indirimli elektrik birim fiyatı olan
        {{ retailOneTimeEnergyCost }}
        TL/KWH üzerinden hesaplanacağına dair bilgilendirildim, kabul
        ediyorum.</label
      >
    </div>
    <div class="col-xs-12 col-lg-6 form-group center-xs">
      <button class="button" @click="goToPayment">İleri</button>
      <p v-if="error" class="notify-title">
        Hata: <br />
        {{
          error.response && error.response.data && error.response.data.code
            ? $localeError(error.response.data.code)
            : error
        }}
      </p>
    </div>
  </section>
</template>

<script>
import firebase from 'firebase/app'

export default {
  name: 'Agreement-2',
  data() {
    return {
      contract: '',
      retailOneTimeEnergyCost: '',
      acceptContract: false,
      acceptInformation: false,
      success: false,
      error: false
    }
  },
  validations: {
    acceptContract: {
      checked: value => value === true
    },
    acceptInformation: {
      checked: value => value === true
    }
  },
  async created() {
    await this.getContract()
  },
  computed: {
    subscriberId() {
      return this.$store.getters.subscriber.id
    }
  },
  mounted() {
    firebase.analytics().logEvent('campaign-agreement')
  },
  methods: {
    async goToPayment() {
      this.error = ''
      this.$v.$touch()
      if (this.$v.$invalid) {
        return (this.error =
          'Lütfen bilgilerinizi kontrol edip tekrar deneyiniz!')
      }
      await this.setContract()
      if (!this.error) {
        this.$store.commit('setCompleted', 2)
        this.$store.commit('setStep', 3)
      }
    },
    async setContract() {
      try {
        const { data } = await this.$axios.post(
          `${process.env.VUE_APP_APIURL}/subscribers/${this.subscriberId}/contract/accept`
        )
        // console.log('acceptContract data', data)
        this.success = data
      } catch (error) {
        // console.log('acceptContract error', error)
        this.error = error
      }
    },
    async getContract() {
      if (!this.subscriberId) return
      try {
        const { data } = await this.$axios.get(
          `${process.env.VUE_APP_APIURL}/subscribers/${this.subscriberId}/contract`
        )
        // console.log('getContract data', data)
        this.contract = data.content
        this.retailOneTimeEnergyCost = data.retailOneTimeEnergyCost
      } catch (error) {
        // console.log('getContract error', error)
        this.error = error
      }
    }
  }
}
</script>
