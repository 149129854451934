<template>
  <section class="payment">
    <div class="box-header">Ödeme</div>
    <div class="row">
      <div
        class="col-xs-12 col-sm-6 col-md-4 col-lg-3"
        v-for="(item, index) in summaryForm"
        :key="index"
      >
        <div class="summary-key">{{ item.key }}</div>
        <div class="summary-value">{{ item.value }}</div>
      </div>
    </div>
    <div class="amounts">
      <div class="summary">
        <div class="amount-type-inner">Son Fatura Tutarı</div>
        <div class="amount-sum">
          {{
            summary.originalTotalAmount
              ? summary.originalTotalAmount.toFixed(2)
              : ''
          }}
          TL
        </div>
        <div class="amount-type-inner">%10 İndirim Tutarı</div>
        <div class="amount-sum">
          -
          {{
            summary.originalTotalAmount && summary.discountedTotalAmount
              ? (
                  summary.originalTotalAmount - summary.discountedTotalAmount
                ).toFixed(2)
              : ''
          }}
          TL
        </div>
        <hr style="width:100%" />
        <div class="amount-type">Teminat Tutarı *</div>
        <div class="amount-sum">
          {{ summary.deposit ? summary.deposit.toFixed(2) : '' }} TL
        </div>
      </div>
      <div class="total">
        <div class="amount-type">Ödenecek Teminat Tutarı</div>
        <div class="amount-sum">
          <span class="reduced-price"
            >{{
              summary.originalTotalAmount
                ? summary.originalTotalAmount.toFixed(2)
                : ''
            }}
            TL</span
          >
          <span class="price"
            >{{
              summary.discountedTotalAmount
                ? summary.discountedTotalAmount.toFixed(2)
                : ''
            }}
            TL</span
          >
        </div>
      </div>
    </div>
    <p style="margin: 0px 0px 40px;">
      * Mevcut tedarikçinize daha önce sunmuş olduğunuz teminat tutarını geçiş
      süreci tamamlandığında iade alabilirsiniz.
    </p>
    <div class="payment">
      <form @submit.prevent="complete" class="row top-xs">
        <div
          class="col-xs-12 form-group"
          :class="{
            'form-group--error': $v.paymentForm.cardNumber.$error
          }"
        >
          <input
            type="text"
            class="form__input"
            ref="cardNumber"
            placeholder="Kart Numarası"
            v-mask="'####-####-####-####'"
            v-model.trim="$v.paymentForm.cardNumber.$model"
          />
        </div>
        <div
          class="col-xs-12 form-group"
          :class="{
            'form-group--error': $v.paymentForm.cardHolderName.$error
          }"
        >
          <input
            type="text"
            class="form__input"
            ref="cardHolderName"
            placeholder="Kart Üzerindeki İsim"
            v-model.trim="$v.paymentForm.cardHolderName.$model"
          />
        </div>
        <div
          class="col-xs-12 col-md-6 form-group"
          :class="{
            'form-group--error': $v.paymentForm.expireDate.$error
          }"
        >
          <input
            type="text"
            class="form__input"
            ref="expireDate"
            placeholder="AA/YY"
            v-mask="'##/##'"
            v-model.trim="$v.paymentForm.expireDate.$model"
          />
        </div>
        <div
          class="col-xs-12 col-md-6 form-group"
          :class="{
            'form-group--error': $v.paymentForm.cvc.$error
          }"
        >
          <div class="tooltip">
            <img src="@/assets/images/info.svg" alt="" />
            <span class="tooltiptext"
              >CVC kodu, 3 haneli olarak kartların arka yüzünde yer alır.</span
            >
          </div>
          <input
            type="text"
            class="form__input"
            ref="cvc"
            placeholder="CVC"
            v-mask="'###'"
            v-model.trim="$v.paymentForm.cvc.$model"
          />
        </div>
        <div
          class="col-xs-12 form-group"
          :class="{
            'form-group--error': $v.paymentForm.saveMyCard.$error
          }"
        >
          <input
            type="checkbox"
            id="saveMyCard"
            v-model="$v.paymentForm.saveMyCard.$model"
          />
          <label class="form__label" for="saveMyCard">
            Kart bilgilerimin sonraki işlemlerde de kullanılmasını kabul
            ediyorum
          </label>
        </div>
        <div class="col-xs-12 col-md-12">
          <img src="@/assets/images/logo-band.png" alt="" />
        </div>
      </form>
    </div>
    <modal ref="threeDSecureModal">
      <template v-slot:header>
        <div class="popup-header"></div>
      </template>
      <template v-slot:body>
        <iframe
          :srcDoc="paymentInit.htmlContent"
          width="100%"
          height="300px"
          frameborder="0"
        ></iframe>
      </template>
    </modal>
    <div class="col-xs-12 col-lg-12 form-group center-xs">
      <button class="button" @click="complete">Başvuruyu Tamamla</button>
      <p v-if="error" class="notify-title">
        Hata: <br />
        {{
          error.response && error.response.data && error.response.data.code
            ? $localeError(error.response.data.code)
            : error
        }}
      </p>
    </div>
  </section>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import firebase from 'firebase/app'
import Modal from '@/components/partials/Modal.vue'

const validCreditCard = value => {
  if (!value) return false
  if (/[^0-9-\s]+/.test(value)) return false

  // The Luhn Algorithm. It's so pretty.
  let nCheck = 0,
    bEven = false
  value = value.replace(/\D/g, '')

  for (var n = value.length - 1; n >= 0; n--) {
    var cDigit = value.charAt(n),
      nDigit = parseInt(cDigit, 10)

    if (bEven && (nDigit *= 2) > 9) nDigit -= 9

    nCheck += nDigit
    bEven = !bEven
  }

  return nCheck % 10 == 0
}

export default {
  name: 'Payment-3',
  components: {
    Modal
  },
  data() {
    return {
      paymentForm: {
        cardNumber: '',
        cardHolderName: '',
        expireDate: '',
        cvc: '',
        threeDSecure: false,
        saveMyCard: false
      },
      summary: {},
      paymentInit: false,
      error: false
    }
  },
  validations: {
    paymentForm: {
      cardNumber: {
        required,
        validCreditCard,
        minLength: minLength(13),
        maxLength: maxLength(19)
      },
      cardHolderName: {
        required,
        minLength: minLength(3)
      },
      expireDate: {
        required,
        minLength: minLength(5)
      },
      cvc: {
        required,
        minLength: minLength(3)
      },
      saveMyCard: {
        checked: value => value === true
      }
    }
  },
  async created() {
    await this.getPayment()
  },
  computed: {
    subscriberId() {
      return this.$store.getters.subscriber.id
    },
    subscriber() {
      return this.$store.getters.subscriber
    },
    summaryForm() {
      const form = {}
      if (!this.summary || !this.summary.subscriber) return form
      if (this.summary.subscriber.authorizedPerson) {
        form.firstName = {
          key: 'Adınız',
          value: this.summary.subscriber.authorizedPerson.firstName
        }
        form.lastName = {
          key: 'Soyadınız',
          value: this.summary.subscriber.authorizedPerson.lastName
        }
      }
      if (this.summary.subscriber.authorizedPerson) {
        form.identityNumber = {
          key: 'TC Kimlik No',
          value: this.summary.subscriber.authorizedPerson.identityNumber
        }
      }
      if (this.summary.subscriber.phoneNumber) {
        form.phoneNumber = {
          key: 'Telefon',
          value: this.summary.subscriber.phoneNumber
        }
      }
      if (this.summary.subscriber.email) {
        form.email = {
          key: 'E-posta Adresi',
          value: this.summary.subscriber.email
        }
      }
      if (this.summary.subscriber.tekilCode) {
        form.tekilCode = {
          key: 'Tekil Kodu',
          value: this.summary.subscriber.tekilCode
        }
      }
      if (this.summary.subscriber.eicCode) {
        form.eicCode = {
          key: 'EIC Kodu',
          value: this.summary.subscriber.eicCode
        }
      }
      if (this.summary.subscriber.meterCity) {
        form.meterCity = {
          key: 'Sayacın Bulunduğu İl',
          value: this.summary.subscriber.meterCity.name
        }
      }
      if (this.summary.subscriber.meterDistrict) {
        form.meterDistrict = {
          key: 'Sayacın Bulunduğu İlçe',
          value: this.summary.subscriber.meterDistrict.name
        }
      }
      if (this.summary.subscriber.meterFullAddress) {
        form.meterFullAddress = {
          key: 'Sayaç Açık Adresi',
          value: this.summary.subscriber.meterFullAddress
        }
      }
      if (this.summary.subscriber.lastBillAmount) {
        form.lastBillAmount = {
          key: 'Son Fatura Tutarı (TL)',
          value: this.summary.subscriber.lastBillAmount
        }
      }
      if (this.summary.subscriber.uploadedBillCount) {
        form.uploadedBillCount = {
          key: 'Faturalar',
          value: this.summary.subscriber.uploadedBillCount + ' Fatura Yüklendi'
        }
      }
      if (this.summary.subscriber.uploadedTaxPlateCount) {
        form.uploadedTaxPlateCount = {
          key: 'Vergi Levhaları',
          value:
            this.summary.subscriber.uploadedTaxPlateCount + ' Dosya Yüklendi'
        }
      }
      if (this.summary.subscriber.uploadedSignatureCircularCount) {
        form.uploadedSignatureCircularCount = {
          key: 'İmza Sirküleri',
          value:
            this.summary.subscriber.uploadedSignatureCircularCount +
            ' Dosya Yüklendi'
        }
      }
      return form
    }
  },
  mounted() {
    firebase.analytics().logEvent('campaign-payment')
  },
  methods: {
    async complete() {
      this.error = ''
      if (this.$store.getters.completed > 2) {
        return this.$store.commit('incrementStep')
      }
      this.$v.$touch()
      if (this.$v.$invalid) {
        if (!this.$v.paymentForm.saveMyCard.checked) {
          return (this.error =
            'Abonelik süreci için kart bilgilerimin sonraki işlemlerde de kullanılmasını kabul etmelisiniz.')
        }
        return (this.error =
          'Lütfen bilgilerinizi kontrol edip tekrar deneyiniz!')
      }
      await this.makePayment()
    },
    async makePayment() {
      try {
        const { data } = await this.$axios.post(
          `${process.env.VUE_APP_APIURL}/subscribers/${this.subscriberId}/init-payment`,
          {
            cardNumber: this.paymentForm.cardNumber.replace(/-/gi, ''),
            expireYear: `20${this.paymentForm.expireDate.substring(3, 5)}`,
            expireMonth: this.paymentForm.expireDate.substring(0, 2),
            cvc: this.paymentForm.cvc,
            cardHolderName: this.paymentForm.cardHolderName,
            threeDSecure: true,
            saveMyCard: this.paymentForm.saveMyCard
          }
        )
        // console.log('makePayment data', data)
        this.paymentInit = data
        if (this.paymentInit) {
          this.$refs.threeDSecureModal.openModal(false)
          setTimeout(this.checkValidation, 10000)
        }
      } catch (error) {
        // console.log('makePayment error', error.response.data.message)
        this.error = error
      }
    },
    async getPayment() {
      try {
        const { data } = await this.$axios.get(
          `${process.env.VUE_APP_APIURL}/subscribers/${this.subscriberId}/payment-summary`
        )
        // console.log('getPayment data', data)
        this.summary = data
      } catch (error) {
        this.error = error
      }
    },
    async checkValidation() {
      try {
        const { data } = await this.$axios.get(
          `${process.env.VUE_APP_APIURL}/payments/${this.paymentInit.paymentId}/status-details`
        )
        if (data && data.status == 'PAID') {
          this.$refs.threeDSecureModal.closeModal()
          this.goToNextStep()
        } else if (data && data.status == 'FAILED') {
          this.$refs.threeDSecureModal.closeModal()
          this.error =
            data.threeDSecureErrorCode || data.errorMessage || data.status
        } else {
          setTimeout(this.checkValidation, 5000)
        }
      } catch (error) {
        this.$refs.threeDSecureModal.closeModal()
        this.error = error
      }
    },
    goToNextStep() {
      this.$store.commit('setCompleted', 3)
      this.$store.commit('setStep', 4)
    }
  }
}
</script>
