<template>
  <div class="container">
    <section class="success">
      <div class="box-header">
        <img class="message-image" src="@/assets/images/checked.svg" alt="" />
        Tebrikler, Başvurunuz Tamamlandı!
      </div>
    </section>
    <section class="hero">
      <h1 class="box-header" v-if="subscriber.authorizedPerson">
        Sayın {{ subscriber.authorizedPerson.firstName }}
        {{ subscriber.authorizedPerson.lastName }},
      </h1>
      <p class="description">
        Pure Energy dijital dünyasına hoşgeldiniz. Süreç tamamlandıktan sonra
        kayıt olduğunuz e-posta üzerinden bilgilendirileceksiniz.
      </p>
      <router-link class="button" to="/">Ana Sayfaya Dön</router-link>
    </section>
  </div>
</template>

<script>
import firebase from 'firebase/app'
export default {
  name: 'Final-4',
  beforeDestroy() {
    this.$store.dispatch('setSubscriberAction', {})
  },
  computed: {
    subscriber() {
      return this.$store.getters.subscriber
    }
  },
  mounted() {
    firebase.analytics().logEvent('campaign-success')
  }
}
</script>
