<template>
  <div class="container wizard">
    <div v-if="step >= 1 && step < 4" class="context-header">
      <button @click="goBack()" class="back"></button>
      <div class="steps">
        <div
          class="step"
          v-bind:class="{ active: step == 1, checked: completed >= 1 }"
          @click="$store.dispatch('setStepAction', 1)"
          style=""
        >
          <div class="number"></div>
          <div class="text">Bilgileriniz</div>
        </div>
        <div
          class="step"
          v-bind:class="{ active: step == 2, checked: completed >= 2 }"
          @click="completed >= 1 && $store.dispatch('setStepAction', 2)"
        >
          <div class="number"></div>
          <div class="text">Sözleşme</div>
        </div>
        <div
          class="step"
          v-bind:class="{ active: step == 3, checked: completed >= 3 }"
          @click="completed >= 2 && $store.dispatch('setStepAction', 3)"
        >
          <div class="number"></div>
          <div class="text">Ödeme</div>
        </div>
      </div>
    </div>
    <Form v-if="step == 0 || step == 1"></Form>
    <Agreement v-if="step == 2"></Agreement>
    <Payment v-if="step == 3"></Payment>
    <Final v-if="step == 4"></Final>
  </div>
</template>
<script>
import Form from '@/components/Form.vue'
import Agreement from '@/components/Agreement.vue'
import Payment from '@/components/Payment.vue'
import Final from '@/components/Final.vue'

export default {
  name: 'Wizard',
  components: {
    Form,
    Agreement,
    Payment,
    Final
  },
  computed: {
    step() {
      return this.$store.getters.step
    },
    completed() {
      return this.$store.getters.completed
    }
  },
  created() {
    this.$store.dispatch('setStepAction', this.step || 1)
  },
  methods: {
    goBack() {
      if (this.step > 1 && this.step <= 4) {
        this.$store.dispatch('setStepAction', this.step - 1)
      } else {
        if (this.$store.getters.salesRepNumber != '') {
          this.$router.push('/stn')
        } else {
          this.$router.push('/')
        }
      }
    }
  },
  metaInfo: {
    title: 'Yeni Talep'
  }
}
</script>
